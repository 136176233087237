<template>
  <div class="basecamp">
    <nav class="basecamp__nav navbar navbar-expand-lg navbar-dark fixed-top d-print-none" style="z-index: 100">
      <div class="d-flex justify-content-between w-100 align-items-center">
        <div class="d-flex align-items-center">
          <router-link to="/">
            <svg class="basecamp__nav__logo">
              <use xlink:href="/img/logo.svg#logo"></use>
            </svg>
          </router-link>
          <ul class="navbar-nav mr-auto d-none d-lg-flex" v-click-outside="closeNav">
            <li
              v-for="navItem in filteredNavItems"
              :key="navItem.path"
              class="nav-item"
              :class="{ dropdown: navItem.children }"
            >
              <div v-if="navItem.children">
                <a
                  class="nav-link dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  @click="openNav(navItem.key)"
                >
                  {{ navItem.label }}
                </a>
                <div
                  class="dropdown-menu"
                  :class="`${openNavItem === navItem.key ? 'show' : ''}`"
                  aria-labelledby="navbarDropdown"
                >
                  <router-link :to="child.path" v-for="(child, i) in navItem.children" :key="i" class="dropdown-item">{{
                    child.label
                  }}</router-link>
                </div>
              </div>
              <router-link
                :to="navItem.path"
                :class="currentRoute === navItem.path ? 'active' : ''"
                v-else
                class="nav-link"
                >{{ navItem.label }}</router-link
              >
            </li>
          </ul>
          <select class="custom-select d-lg-none" @change="navigate">
            <optgroup v-for="navItem in filteredNavItems" :key="navItem.path" :label="navItem.label">
              <option
                :selected="currentRoute === navItem.path"
                v-for="(child, i) in navItem.children"
                :key="i"
                :value="child.path"
              >
                {{ child.label }}
              </option>
              <option :selected="currentRoute === navItem.path" v-if="!navItem.children" :value="navItem.path">{{
                navItem.label
              }}</option>
            </optgroup>
          </select>
        </div>
        <div class="text-white">
          <button class="btn btn" @click="search">🔎</button>
          <button
            type="button"
            class="btn ml-2"
            @click="toggleDemo"
            v-if="$store.state.demo && $store.state.role === 'admin'"
            :class="typeof $store.state.demo.url === 'string' ? 'btn-success' : 'btn-outline-light'"
          >
            Demo
          </button>
          <button type="button" class="btn ml-2 btn-primary-outline text-white" @click="logout">Logout</button>
        </div>
      </div>
    </nav>

    <div class="basecamp__content" id="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { auth, db, env, storage } from '@/shared/firebase'

export default {
  data() {
    return {
      devMode: false,
      openNavItem: null,
      navItems: [
        {
          label: 'Operations',
          key: 'operations',
          visibleForSales: true,
          children: [
            { path: '/orders', label: 'Orders' },
            { path: '/materialshipments', label: 'Material Shipments', visibleForSales: true },
            { path: '/kiosk', label: 'Kiosk' },
            { path: '/shoppinglists', label: 'Shopping Lists' }
          ]
        },
        {
          label: 'Organizations',
          path: '/organizations',
          visibleForSales: true
        },
        { path: '/products', label: 'Products', visibleForSales: true },
        {
          label: 'Accounting',
          key: 'accounting',
          children: [
            { path: '/invoices', label: 'Invoices' },
            { path: '/transactions', label: 'Transactions' },
            { path: '/creditnotes', label: 'Credit Notes' }
          ]
        },
        {
          label: 'Analyze',
          key: 'analyze',
          children: [
            { path: '/statistics', label: 'Statistics' },
            { path: '/reports', label: 'Reports' }
          ]
        },
        {
          label: 'Documents',
          key: 'documents',
          children: [
            { path: '/newsletters', label: 'Newsletters' },
            { path: '/print-catalog', label: 'Catalogue' },
            { path: '/warehousecodes', label: 'Warehouse Codes' },
            { path: '/palletcodes', label: 'Pallet Codes' },
            { path: '/wagoncodes', label: 'Wagon Codes' }
          ]
        }
      ],
      hasAdminAccess: false
    }
  },

  created: function() {
    this.devMode = auth.currentUser.email === 'marcel@mitemma.de'
  },
  watch: {
    $route() {
      this.openNavItem = null
    }
  },
  methods: {
    openNav(navItem) {
      this.openNavItem = this.openNavItem === navItem ? null : navItem
    },
    closeNav() {
      this.openNavItem = null
    },
    logout: function() {
      auth.signOut()
    },
    navigate: function(e) {
      this.$router.push(e.target.value)
    },
    async search() {
      const input = prompt(`You're looking for...`)
      if (input) {
        // const invoiceId = input.match(/[sS]-[0-9]{3,4}\/[0-9]{4,5}/g)
        let invoiceCollectionId = input.match(/[sS]-[0-9]{3,5}/g)

        if (invoiceCollectionId) {
          invoiceCollectionId = `S-${invoiceCollectionId[0].substr(2)}`
          const bucketName = env === 'production' ? 'emma-billings' : 'emma-billings-dev'
          const invoiceCollectionUrl = await storage
            .refFromURL(`gs://${bucketName}/billings/${invoiceCollectionId}.pdf`)
            .getDownloadURL()
          window.open(invoiceCollectionUrl, '_blank')
        }
      }
    },
    toggleDemo: function() {
      if (this.$store.state.demo.url) {
        db.doc('website/demo').update({
          url: false
        })
      } else {
        const url = prompt('Demo Url?')
        if (url) {
          db.doc('website/demo').update({
            url
          })
        }
      }
    }
  },
  computed: {
    filteredNavItems() {
      const { role } = this.$store.state
      if (role === 'sales') {
        return this.navItems
          .filter(i => i.visibleForSales)
          .map(items => {
            if (items.children) items.children = items.children.filter(i => i.visibleForSales)
            return items
          })
      }
      return this.navItems
    },
    currentRoute() {
      return '/' + this.$route.path.split('/')[1]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/variables';

.basecamp {
  display: flex;
  flex-direction: column;
  height: 100%;

  &__nav {
    background: $color-main;

    &__logo {
      fill: #fff;
      width: 90px;
      height: 30px;
      margin: 0.5rem 1rem 0.625rem;
    }
  }

  &__content {
    flex-grow: 1;
    margin-top: 4rem;
    padding: 2rem;
  }
}

@media print {
  .basecamp {
    display: block;
    &__nav {
      display: none;
    }
    &__content {
      margin: 0;
      padding: 0;
      overflow-y: auto;
    }
  }
}
</style>
